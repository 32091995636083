import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "addressContainer",
    "payNowContainer",
    "targetedTherapyOnlyPayeeClinic",
    "targetedTherapyOnlyPayeeParent",
    "parentContainer",
    "parentPhoneNumber",
    "parentEmail",
    "wwShippingTypeContainer",
    "allergiesInputContainer",
    "allergiesInput",
    "allergiesQuestionContainer",
    "medicalRationaleContainer",
    "streetAddress",
    "city",
    "zip",
    "pharmacyContainer",
    "pharmacyContainer_0",
    "pharmacyContainer_1",
    "pharmacyContainer_2",
    "totalPriceContainer",
    "totalPrice",
    "state",
    "drug",
    "drug_0",
    "drug_1",
    "drug_2",
    "quantity",
    "quantity_0",
    "quantity_1",
    "quantity_2",
    "refills",
    "refills_0",
    "refills_1",
    "refills_2",
    "instructions",
    "instructions_0",
    "instructions_1",
    "instructions_2",
  ];

  drug_pharmacy_info = {};
  current_section = null;
  current_section_postfix = "";
  current_section_drug_el = null;
  current_quantity = "";

  initialize() {
    const search_params = new URLSearchParams(window.location.search);
    this.case_uid = search_params.get("uid");
    window.controller = this;
  }

  reset_form = () => {
    this.current_section.dataset.price = "0";
    return false;
  }

  current_strength = () => {
    return parseFloat(document.getElementById(`strength${this.current_section_postfix}`).value);
  }
  update_drug_selection = async _event => {
    console.log("update drug selection")
    this.set_current_section(_event);
    let drug = this.drugTarget.value;
    if(drug === ""){
      return this.reset_form();
    }
    if(drug === "-1"){
      this.show_alert("If you don’t see the drug you’re looking for, please contact <a target='_blank' href='mailto:operations@fidocure.com'>operations@fidocure.com.</a>")
      return this.reset_form();
    }
    let state = document.getElementById("shipping_state").value;
    console.log(state);
    let result = await this.get_drug_pharmacy_info(drug, state)
    console.log(result)
    if (Object.keys(result).length === 0) {
      let drug_name = this.current_section_drug_el.text;
      if(["Trametinib Dimethyl Sulfoxide", "Olaparib"].includes(drug_name)){
        this.show_alert(`Unfortunately, ${drug_name} is currently out of stock at Wedgewood.<br><br>New orders can be placed with Stokes Pharmacy:<br>Phone: 800-754-5222<br>Fax: 800-440-5899<br><br>Please mention "FidoCure" for accurate billing.`);
      } else {
        this.show_alert("Sorry, the pharmacies we work with cannot ship to the state selected. For help please contact <a target='_blank' href='mailto:operations@fidocure.com'>operations@fidocure.com.</a>");
      }
      return this.reset_form();
    }

    // handles the scenario of this field having an initial value. Forces javascript to play out
    if(document.getElementById("case_drug_sale_ship_to_owner_or_clinic_clinic").checked){
      document.getElementById("case_drug_sale_ship_to_owner_or_clinic_clinic").click();
    } else if(document.getElementById("case_drug_sale_ship_to_owner_or_clinic_owner").checked){
      document.getElementById("case_drug_sale_ship_to_owner_or_clinic_owner").click();
    }

    this.set_strength_element(document.getElementById("strength"))
    if(result.max_strength) {
      this.strengthTarget.max = result.max_strength
    }
    this.update_pharmacy_options(result)
  }

  update_multiple_drug_selection = async _event => {
    console.log("update drug selection")
    this.set_current_section(_event);
    let section_id = this.current_section.dataset.sectionId;
    console.log(_event.target);
    let drug = _event.target.value;
    if (drug === "-1") {
      this.set_select_value(_event.target, "")
      M.Modal.getInstance(document.querySelector("#order-rx-modal"), {}).open();
      this.current_section.dataset.price = "0";
      await this.update_pharmacy_options()
      return false;
    }
    let state = document.getElementById("shipping_state").value;
    console.log(state);
    let result = await this.get_drug_pharmacy_info(drug, state)
    console.log(result)
    if (Object.keys(result).length === 0) {
      this.show_alert("Sorry, the pharmacies we work with cannot ship to the state selected. For help please contact <a href='mailto:operations@fidocure.com'>operations@fidocure.com.</a>")
      this.set_select_value(_event.target, "")
      this.current_section.dataset.price = "0";
      this.update_pharmacy_options(result)
      return false;
    }
    this.update_pharmacy_options(result)
    this.set_strength_element(document.getElementById(`strength_${section_id}`))
  }

  update_shipping_address = _event => {
    let data = this.addressContainerTarget.dataset;
    let selected_state = document.getElementById("shipping_state").value;
    if(document.getElementById("case_drug_sale_ship_to_owner_or_clinic_clinic").checked){
      data["hospitalStreetAddress"] = this.streetAddressTarget.value;
      data["hospitalCity"] = this.cityTarget.value;
      data["hospitalZip"] = this.zipTarget.value;
      data["hospitalState"] = selected_state;
    } else if(document.getElementById("case_drug_sale_ship_to_owner_or_clinic_owner").checked) {
      data["parentStreetAddress"] = this.streetAddressTarget.value;
      data["parentCity"] = this.cityTarget.value;
      data["parentZip"] = this.zipTarget.value;
      data["parentState"] = selected_state;
    }
  }

  set_strength_element = (strength_el) => {
    let instance = M.Autocomplete.getInstance(strength_el);
    instance.options.minLength = 1;
    instance.options.sortFunction = (a, b, inputString) => {
      return parseFloat(a) - parseFloat(b);
    }
    instance.updateData(this.autocomplete_strengths(this.current_section_drug_el.text));
  }

  autocomplete_strengths = (drug_name, value) => {
    console.log(`drug_name: ${drug_name} - value: ${value}`)
    let options = this.drug_pharmacy_info[drug_name]["wedgewood"] || [];
    options = options.filter(item => [15, 30, 45, 60].includes(item.quantity));
    if(value !== undefined){
      options = options.filter(item => item.strength.toString().startsWith(value));
    }
    console.log(options);
    let strength_options = {}
    options.forEach(item => {strength_options[item.strength] = null})
    console.log(strength_options);
    return strength_options;
  }

  validate_strength = _event => {
    // First make sure only valid numeric keys are entered
    let valid_key = ["1","2","3","4","5","6","7","8","9","0",".",].includes(_event.key);
    if(!valid_key) {
      _event.preventDefault();
      return;
    }
    let value = _event.target.value + _event.key;
    if(value.split('.').length > 2){ // Make sure at most one decimal point
      _event.preventDefault();
    }
    // Verify pattern is of a float number (e.g. no multiple decimal points
    const regex = /^\d*\.\d{3,}$/;
    if(regex.test(value)){
      _event.preventDefault();
    }
  }

  change_strength = (_event) => {
    console.log("change strength");
    if(this.is_lifetime() && this.current_quantity !== "") {
      this.update_prescription(_event);
    } else {
      this.set_current_section(_event);
      this.reset_current_quantity();
      this.show_comparison();
    }
  }

  show_comparison = () => {
    let strength = this.current_strength();
    let selected_drug_el = this.current_section_drug_el;
    let drug_name = selected_drug_el.text;
    let pharmacy_container = `pharmacyContainer${this.current_section_postfix}`;
    if(strength && selected_drug_el.value !== ""){
      this.show_target_input(pharmacy_container);
    }else{
      this.hide_target_input(pharmacy_container);
      return
    }
    let pharmacies = ["best_pet_rx", "wedgewood"]; //, "mixlab"];
    pharmacies.forEach((pharmacy) => {
      let el = this[pharmacy_container + "Target"].getElementsByClassName(pharmacy)[0];
      let status = this.drug_pharmacy_info[drug_name][`${pharmacy}_status`];
      let state = document.getElementById("shipping_state").value;
      if(pharmacy === "best_pet_rx" && status === "Enabled" && !valid_states.includes(state)) {
        status = "Unable to Ship"
      }
      switch (status) {
        case "Out of Stock":
          this.disable_pharmacy_box(el, "Drug is temporarily out of stock")
          break;
        case "Unable to Ship":
          this.disable_pharmacy_box(el, "Unable to ship to selected state")
          break;
        default:
          // selectable_pharmacies.push(pharmacy);
          el.classList.remove("disabled");
          el.querySelector(".out-of-stock").classList.add("hide");
      }

      if(this.drug_pharmacy_info[drug_name][`${pharmacy}_status`] === "Disabled"){
        el.classList.add('hide');
        return
      }
      let quantities = {};
      let show_similar_skus = false;
      switch (pharmacy) {
        case "best_pet_rx":
          let bprx_item = this.drug_pharmacy_info[drug_name]["best_pet_rx"].filter((item) => {return strength <= item["strength_max"] && strength >= item["strength_min"]});
          ["15", "30", "45", "60"].forEach(key => {quantities[key] = bprx_item[0][`price_${key}`]});
          break;
        case "wedgewood":
          let ww_items = this.drug_pharmacy_info[drug_name]["wedgewood"].filter((item) => {return item["strength"] === strength});
          console.log(ww_items);
          ww_items.forEach((item) => {quantities[item["quantity"]] = item["price"]});
          show_similar_skus = (ww_items.length === 0)
          break;
      }
      console.log(quantities);
      ["15", "30", "45", "60"].forEach((quantity) => {
        let price = quantities[quantity] ? this.format_price(quantities[quantity]) : "N.A.";
        el.querySelector(`.quantity_${quantity}`).innerHTML = `${quantity} capsules - ${price}`;
        el.querySelector(`.quantity_radio_${quantity}`).classList.toggle("disabled", !quantities[quantity]);
      });
      this.show_pharmacy_section(el, "quantity_price_container");
      if(show_similar_skus){
        this.show_ww_similar_skus(el, this.similar_ww_skus(strength));
      }
    });
  }

  show_pharmacy_section = (pharmacy_card_el, section_class) => {
    let section_classes = [".exact_sku_container", ".similar_skus_container"] //, ".quantity_price_container"];
    section_classes.forEach((el) => {
      pharmacy_card_el.querySelectorAll(section_classes.join(",")).forEach((item) => {item.classList.add("hide")});
    });
    pharmacy_card_el.getElementsByClassName(section_class).forEach((item) => {item.classList.remove("hide")});
  }

  change_quantity = _event => {
    console.log("Change quantity");
  }
  update_quantity = _event => {
    console.log("Update quantity");
    this.set_current_section(_event);
    if(_event.target.classList.contains("disabled")){
      this.show_alert("This quantity is not available for this pharmacy. Please select a different quantity or reach out to <a target='_blank' href='mailto:operations@fidocure.com'>operations@fidocure.com</a>")
      _event.preventDefault();
      _event.stopPropagation();
      return false;
    }
    this.current_quantity = _event.target.value;
    this.update_prescription(_event);
    let pharmacy_container = `pharmacyContainer${this.current_section_postfix}`;
    this.show_pharmacy_section(this[pharmacy_container + "Target"], "quantity_price_container")
  }

  reset_current_quantity = () => {
    this.current_section.querySelectorAll(".pharmacy-card [type=radio]").forEach(el => {el.checked = false; el.required = false; el.disabled = false;})
    this.current_quantity = "";
  }

  update_lifetime_ppo = async _event => {
    console.log("update_lifetime_ppo");
    this.adjust_layout_for_lifetime();
  }

  update_prescription = async _event => {
    console.log("Update prescription");
    this.set_current_section(_event);
    await this.update_pharmacy_options();
    if(!this.is_lifetime()){
      this.update_pharmacy(_event);
    }
  }

  set_current_section = (_event) => {
    this.current_section = _event.target.closest('.multiple_drug_sales') || document.getElementById('multiple_drug_sale');
    this.set_current_section_postfix(this.current_section)
    let drug_select = this.current_section.dataset.sectionId ? this[`drug_${this.current_section.dataset.sectionId}Target`] : this.drugTarget
    this.current_section_drug_el = drug_select.querySelector("option:checked")
  }

  set_current_section_postfix = (section) => {
    this.current_section_postfix = this.current_section.dataset.sectionId ? `_${this.current_section.dataset.sectionId}` : "";
  }

  update_pharmacy_options = async () => {
    console.log("update_pharmacy_options");
    this.adjust_layout_for_lifetime();
    let strength = this.current_strength();
    let quantity = this.current_quantity;
    if(quantity === "" || strength === 0) {
      this.set_price(0);
      this.set_pharmacies();
      return;
    }
    let drug = this[`drug${this.current_section_postfix}Target`].value;
    let result = await this.get_prices(drug, strength, quantity)
    // this.set_price(result || 0);
    this.set_pharmacies(result);
  }

  show_hide_medical_rationale = () => {
    let pharmas = Array.from(document.getElementsByClassName("pharmacy_input"));
    let show_medical_rationale = pharmas.some(input => input.value === "Wedgewood");
    let input_el = this.medicalRationaleContainerTarget.querySelector("select")
    if(show_medical_rationale) {
      this.show_target_input("medicalRationaleContainer");
      if(input_el.value === "") {
        input_el.value = "Reduce Compliance";
      }
      input_el.required = true;
    } else {
      this.hide_target_input("medicalRationaleContainer");
      if(input_el.value === "Reduce Compliance") {
        input_el.value = "";
      }
      input_el.required = false;
    }
    M.FormSelect.init(input_el);
  }

  get_pharmacies_for_lifetime = async () => {
    let drug_sale_data = []
    let selected_first_drug = this.drugTarget.value;
    drug_sale_data.push({
      drug_inventory_id: parseInt(selected_first_drug),
      strength: parseFloat(document.getElementById("strength").value),
      quantity: parseInt(this.quantityTarget.value),
      id: 0
    })

    for (const idx of [0, 1, 2]) {
      let drug = this[`drug_${idx}Target`]
      if(!drug.value) {
        continue
      }
      drug_sale_data.push({
        drug_inventory_id: parseInt(drug.value),
        strength: parseFloat(document.getElementById(`strength_${idx}`).value),
        quantity: parseInt(this[`quantity_${idx}Target`].value),
        id: idx + 1
      })
    }

    const response = await fetch("/drug_sales/lifetime_select_pharmacy", {
      method: 'post',
      body: JSON.stringify({data: drug_sale_data, state: this.stateTarget.value}),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
      }
    })
    const result = await response.json();
    console.log(result)
    return result;
  }

  is_lifetime = () => {
    let is_lifetime = null;
    try {
      is_lifetime = document.getElementById("case_case_information_attributes_at_cost_therapy_pricing_false").checked
    } catch (e) {
      is_lifetime = document.getElementById("case_case_information_attributes_at_cost_therapy_pricing").value === "false"
    }
    return is_lifetime
  }

  adjust_layout_for_lifetime = () => {
    document.getElementById("drug_sale_submission_form").classList.toggle("lifetime", this.is_lifetime())
  }

  sections = () => {
    return document.querySelectorAll(".multiple_drug_sales, #multiple_drug_sale");
  }

  format_price = (cents) => {
    return `$${(cents/100).toFixed(2)}`;
  }

  total_price = () => {
    let sections = Array.from(this.sections());
    let prices = sections.map((currentValue, index, arr) => {return currentValue.dataset.price ? parseFloat(currentValue.dataset.price) : 0});
    let shipping_price = 0;
    if(!this.wwShippingTypeContainerTarget.classList.contains("hide") && document.querySelector('input[name="wvp_shipping_type"]:checked')){
      shipping_price = parseInt(document.querySelector('input[name="wvp_shipping_type"]:checked').dataset["price"]);
    }
    return prices.reduce((accumulator, currentValue) => {return accumulator + currentValue}, shipping_price) / 100;
  }

  set_price = (price) => {
    this.current_section.dataset.price = price;
    if(price){
      this.set_total_price();
    }
  }

  set_pharmacies = (data) => {
    console.log("set_pharmacies");
    let pharmacy_container = `pharmacyContainer${this.current_section_postfix}`;
    if(this.is_empty(data) || this.is_lifetime()) {
      this.hide_target_input(pharmacy_container);
      this.set_price(0);
      return;
    }
    this.show_target_input(pharmacy_container);
    this[pharmacy_container + "Target"].querySelector(".pharmacy_input").required = true;
    let pharmacies = ["best_pet_rx", "wedgewood"]; //, "mixlab"];
    let selectable_pharmacies = []
    pharmacies.forEach((pharmacy) => {
      let el = this[pharmacy_container + "Target"].getElementsByClassName(pharmacy)[0];
      let price_el = el.querySelector(".card-price");
      if(data[pharmacy] && data[pharmacy]["status"] !== "Disabled"){
        el.classList.remove('hide');
        el.dataset["price"] = data[pharmacy]["price"];
        price_el.innerText = this.format_price(data[pharmacy]["price"]);
        switch (data[pharmacy]["status"]) {
          case "Out of Stock":
            this.disable_pharmacy_box(el, "Drug is temporarily out of stock")
            break;
          case "Unable to Ship":
            this.disable_pharmacy_box(el, "Unable to ship to selected state")
            break;
          default:
            selectable_pharmacies.push(pharmacy);
            el.classList.remove("disabled");
            el.querySelector(".out-of-stock").classList.add("hide");
        }
        console.log(data[pharmacy]["price"]);
        if(data[pharmacy]["price"] === ""){
          this.show_ww_similar_skus(el, this.similar_ww_skus(this.current_strength()));
        } else {
          this.show_pharmacy_section(el, "exact_sku_container");
        }
      } else {
        price_el.innerText = "";
        el.classList.add('hide');
      }
    });
    if(selectable_pharmacies.length === 1){
      this[pharmacy_container + "Target"].getElementsByClassName(selectable_pharmacies[0])[0].click();
    }
  }

  show_ww_similar_skus = (container_el, sku_options) => {
    if(sku_options.length > 0) {
      let select_el = container_el.querySelector(".similar_sku_select");
      let select_instance = M.FormSelect.getInstance(select_el);
      select_instance.destroy();
      select_el.innerHTML = sku_options.map((st) => {return `<option value="${st['strength']}-${st['quantity']}">${st["strength"]}mg / ${st["quantity"]} Caps / ${this.format_price(st["price"])}</option>`});
      select_el.innerHTML = `<option value="">Click to view similar SKUs</option>` + select_el.innerHTML + `<option value="other">I don't see what I want</option>`;
      M.FormSelect.init(select_el, {dropdownOptions: sku_options});
      container_el.querySelector(".similar_skus_body").classList.remove("hide");
    } else {
      container_el.querySelector(".similar_skus_body").classList.add("hide");
    }
    this.show_pharmacy_section(container_el, "similar_skus_container");
  }

  select_sku = _event => {
    let value = _event.target.value;
    if(value === "other"){
      this.show_alert("If you would like a strength and/or quantity not available, please contact the FidoCUre Operations Team at <a href='mailto:operations@fidocure.com'>operations@fidocure.com</a>")
      return false;
    }
    let [strength, quantity] = value.split("-");
    this.set_current_section(_event);
    document.getElementById(`strength${this.current_section_postfix}`).value = strength;
    this.show_comparison();
    // the next 2 lines make sure the correct quantity is selected on the ww card
    this.current_quantity = quantity;
    this.update_pharmacy(_event);
  }

  disable_pharmacy_box = (el, msg) => {
    el.classList.add("disabled");
    el.querySelectorAll("[type=radio]").forEach(el => {el.checked = false; el.required = false; el.disabled = true;})
    el.querySelector(".selected").style.visibility = "hidden";
    let out_of_stock_note = el.querySelector(".out-of-stock");
    out_of_stock_note.classList.remove("hide");
    out_of_stock_note.querySelector("span").innerHTML = msg;

  }

  set_total_price = () => {
    let total_price = this.total_price()
    if(!total_price){
      // document.getElementById("drug_sale_submission_form").reportValidity()
      this.hide_target_input("totalPriceContainer");
      return;
    }
    this.totalPriceTarget.innerHTML = `$${total_price.toFixed(2)}`;

    this.show_target_input("totalPriceContainer")
  }

  get_drug_pharmacy_info = async (drug, state) => {
    if(!drug || !state) {
      return false;
    }
    const response = await fetch(`/drug_inventories/${drug}/?state=${state}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
      }
    })
    let res = await response.json();
    this.drug_pharmacy_info = Object.assign({}, this.drug_pharmacy_info, res);
    return res;
  }

  similar_ww_skus = (strength)=> {
    let data = this.drug_pharmacy_info[this.current_section_drug_el.text];

    let similar_skus = [];
    let at_least_one_sku_same_strength = false;

    if (data["wedgewood"]) {
      data["wedgewood"].forEach((item) => {
        if (![15, 30, 45, 60].includes(parseInt(item["pack_size"]))) {
          return;
        }

        let different_strength = parseFloat(strength) !== parseFloat(item["strength"]);

        if (different_strength) {
          let strength_diff = Math.abs(parseFloat(strength) - parseFloat(item["strength"])) / parseFloat(item["strength"]);
          strength_diff = parseFloat(strength_diff.toFixed(3));

          if (different_strength) {
            if (strength_diff < 0.1) {
              similar_skus.push({
                diff: strength_diff,
                strength: parseFloat(item["strength"]),
                quantity: parseInt(item["pack_size"]),
                price: item["price"]
              });
            }
          } else {
            at_least_one_sku_same_strength = true;
          }
        }
      });

      similar_skus.sort((a, b) => a.diff - b.diff);
      if (at_least_one_sku_same_strength) {
        return []
      }
    }
    return similar_skus;
  }

  get_prices = async (drug, strength, quantity) => {
    if(!drug || !strength || !quantity) {
      return;
    }
    let state = this.stateTarget.value;
    const response = await fetch(`/drug_inventories/search?id=${drug}&strength=${strength}&quantity=${quantity}&state=${state}`, {
      method: 'get',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].content
      }
    })
    return await response.json();
  }

  update_ww_shipping = _event => {
    let target = _event.target;
    let price = target.dataset.price;

    let card = target.closest('.pharmacy-card');
    this.set_total_price();
  }

  update_pharmacy = _event => {
    console.log('update_pharmacy');
    this.set_current_section(_event);
    let target = _event.target;
    if(target.classList.contains('select-dropdown')) {
      return false;
    }
    // this.make_target_input_required("submitterVetFalse")
    // this.make_target_input_required("submitterVetTrue")
    while(!target.classList.contains("service-card")){
      target = target.parentNode;
    }
    if(target.classList.contains("disabled")){
      return false;
    }
    let input = this.current_section.querySelector(".pharmacy_input")
    if (target.classList.contains("ww-card")) {
      input.value = "Wedgewood";
      target.querySelectorAll("[type=radio]").forEach(el => {el.required=true; el.disabled = false;})
    } else if ((target.classList.contains("bprx-card"))) {
      input.value = "Best Pet Rx";
      this.current_section.querySelectorAll(".ww-card [type=radio]").forEach(el => {el.checked = false; el.required = false; el.disabled = false;})
    }

    let price = target.dataset["price"];
    this.set_price(price);
    this.current_section.querySelectorAll(".selected-container .selected").forEach(el => { el.style.visibility = 'hidden'; })
    target.querySelector(".selected-container .selected").style.visibility = "visible";

    // select the quantity radio in the selected card;
    if(this.current_quantity !== "" && !target.querySelector(`.quantity_radio_${this.current_quantity}`).checked){
      target.querySelector(`.quantity_${this.current_quantity}`).click();
    }
    this.show_hide_medical_rationale();
    this.show_hide_ww_shipping_type();
  }

  show_hide_ww_shipping_type = () => {
    const ww_cards_selected = document.querySelectorAll('.ww-card div.selected');
    const visible_ww_cards_selected = Array.from(ww_cards_selected).filter(card => {
      return window.getComputedStyle(card).visibility === 'visible';
    });
    if(visible_ww_cards_selected.length > 0){
      this.show_target_input("wwShippingTypeContainer")
      this.make_target_input_required("wvp_shipping_type_1_Day_Delivery")
      this.make_target_input_required("wvp_shipping_type_2_Day_Delivery")
      this.make_target_input_required("wvp_shipping_type_Standard")
    } else {
      this.hide_target_input("wwShippingTypeContainer")
      this.make_target_input_unrequired("wvp_shipping_type_1_Day_Delivery")
      this.make_target_input_unrequired("wvp_shipping_type_2_Day_Delivery")
      this.make_target_input_unrequired("wvp_shipping_type_Standard")
    }
  }

  update_shipping_target = async _event => {
    let target = _event.target;
    console.log(target.value);
    let data = this.addressContainerTarget.dataset;
    this.streetAddressTarget.value = "";
    this.cityTarget.value = "";
    this.zipTarget.value = "";

    if(target.value === "owner") {
      this.set_select_value("state", data["parentState"])
      this.streetAddressTarget.value = data["parentStreetAddress"]
      this.cityTarget.value = data["parentCity"]
      this.zipTarget.value = data["parentZip"]
    } else if (target.value === "clinic"){
      this.set_select_value("state", data["hospitalState"])
      this.streetAddressTarget.value = data["hospitalStreetAddress"]
      this.cityTarget.value = data["hospitalCity"]
      this.zipTarget.value = data["hospitalZip"]
    }
    this.show_target_input("addressContainer");

    this.sections().forEach((section) => {
      if(!section.classList.contains("hide")){
        this.current_section = section
        this.set_current_section_postfix(section)
        this.update_pharmacy_options();
      }
    });
  }

  update_allergies = async _event => {
    let target = _event.target;
    console.log(target.value);
    if(target.value === "true") {
      this.show_target_input("allergiesInputContainer");
      this.make_target_input_required("allergiesInput");
      if(this.allergiesInputTarget.value === "None") {
        this.allergiesInputTarget.value = ""
      }
    } else if (target.value === "false"){
      this.hide_target_input("allergiesInputContainer");
      this.make_target_input_unrequired("allergiesInput");
      this.allergiesInputTarget.value = "None";
    }
  }

  change_payee = _event => {
    let target = _event.target;
    console.log(target.value);
    if(target.value === "clinic") {
      this.make_target_input_required("targetedTherapyOnlyPayeeClinic")
      this.make_target_input_required("targetedTherapyOnlyPayeeParent")
      this.show_target_input("payNowContainer")
      this.make_target_input_unrequired("parentEmail")
      this.make_target_input_unrequired("parentPhoneNumber")
      this.hide_target_input("parentContainer")
    } else {
      this.hide_target_input("payNowContainer")
      this.make_target_input_unrequired("targetedTherapyOnlyPayeeClinic")
      this.make_target_input_unrequired("targetedTherapyOnlyPayeeParent")
      this.make_target_input_required("parentEmail")
      this.make_target_input_required("parentPhoneNumber")
      this.show_target_input("parentContainer")
    }
  }

  add_drug = _event => {
    let new_container = document.querySelector(".multiple_drug_sales.hide")
    new_container.classList.remove('hide')
    let section_id = new_container.dataset.sectionId
    this.make_target_input_required(`drug_${section_id}`)
    this.make_target_input_required(`strength_${section_id}`)
    // this.make_target_input_required(`quantity_${section_id}`)
    this.make_target_input_required(`refills_${section_id}`)
    this.make_target_input_required(`instructions_${section_id}`)
    if(document.querySelector(".multiple_drug_sales.hide") === null) {
      document.querySelector(".add-other").classList.add('hide')
    }
  }

  remove_drug = _event => {
    _event.preventDefault()
    this.set_current_section(_event)
    let section_id = this.current_section.dataset.sectionId;
    this.set_select_value(`drug_${section_id}`, "");
    this.make_target_input_unrequired(`drug_${section_id}`);
    this.clear_and_make_unrequired(`strength_${section_id}`);
    this.make_target_input_unrequired(`quantity_${section_id}`);
    this.set_select_value(`quantity_${section_id}`, "");
    this.clear_and_make_unrequired(`refills_${section_id}`);
    this.clear_and_make_unrequired(`instructions_${section_id}`);
    this.current_section.classList.add('hide');
    this.set_price(0);
    this.current_section.querySelector(".pharmacy_input").required = false;
    this.set_total_price();
  }

  submit_confirmation = async _event => {
    _event.preventDefault();
    let pharmacies;
    let validity = document.getElementById("drug_sale_submission_form").reportValidity();
    if(!validity) {
      return;
    }

    if(this.is_lifetime()){
      pharmacies = await this.get_pharmacies_for_lifetime()
    }

    let selected_first_drug = this.drugTarget;
    let ship_to = document.getElementById("case_drug_sale_ship_to_owner_or_clinic_clinic").checked ? "Clinic" : "Pet Parents";
    document.querySelectorAll('#drug-sale-submission-confirmation .ship_to').forEach((el) => {el.innerHTML = ship_to});
    let address = `${this.streetAddressTarget.value} ${this.cityTarget.value}, ${this.stateTarget.value}, ${this.zipTarget.value}`;
    document.querySelectorAll('#drug-sale-submission-confirmation .shipping_address').forEach((el) => {el.innerHTML = address});
    document.querySelectorAll('#drug-sale-submission-confirmation .total_price').forEach((el) => {el.innerHTML = `$${this.total_price().toFixed(2)}`});
    let first_section = document.getElementById("multiple_drug_sale")
    let first_drug_price = first_section.dataset.price;
    let pharmacy = pharmacies ? pharmacies[0]["pharmacy"] : first_section.querySelector(".pharmacy_input").value;
    let shipping_added = false;
    if(pharmacy === "Wedgewood" && !this.is_lifetime()) {
      first_drug_price = parseInt(first_drug_price) + parseInt(document.querySelector('input[name="wvp_shipping_type"]:checked').dataset["price"]);
      shipping_added = true;
    }

    let strength = document.getElementById("strength").value
    let quantity = this.is_lifetime() ? this[`quantityTarget`].value : document.querySelector("[name='case[drug_sale][quantity]']:checked").value
    let order_rows_html = this.confirmation_drug_row(selected_first_drug.options[selected_first_drug.selectedIndex].innerHTML, strength, quantity, first_drug_price, this.refillsTarget.value, pharmacy, this.instructionsTarget.value);
    let order_sections_html = this.confirmation_drug_section(selected_first_drug.options[selected_first_drug.selectedIndex].innerHTML, strength, quantity, first_drug_price, this.refillsTarget.value, pharmacy, this.instructionsTarget.value);

    for (const idx of [0, 1, 2]) {
      let drug = this[`drug_${idx}Target`]
      if(!drug.value) {
        continue
      }
      let section = document.getElementById(`multiple_drug_sale_${idx}`);
      let price = section.dataset.price;
      let pharmacy = pharmacies ? pharmacies[idx + 1]["pharmacy"] : section.querySelector(".pharmacy_input").value;
      if(pharmacy === "Wedgewood" && !this.is_lifetime() && !shipping_added) {
        price += parseInt(document.querySelector('input[name="wvp_shipping_type"]:checked').dataset["price"]);
        shipping_added = true;
      }
      let strength = document.getElementById(`strength_${idx}`).value
      let quantity = this.is_lifetime() ? this[`quantity_${idx}Target`].value : document.querySelector(`[name='case[drug_sales_attributes][${idx}][quantity]']:checked`).value
      order_rows_html += this.confirmation_drug_row(drug.options[this[`drug_${idx}Target`].selectedIndex].innerHTML, strength, quantity, price, this[`refills_${idx}Target`].value, pharmacy, this[`instructions_${idx}Target`].value);
      order_sections_html += this.confirmation_drug_section(this[`drug_${idx}Target`].options[this[`drug_${idx}Target`].selectedIndex].innerHTML, strength, quantity, price, this[`refills_${idx}Target`].value, pharmacy, this[`instructions_${idx}Target`].value);
    }
    document.querySelector("#drug-sale-submission-confirmation .order-table").innerHTML = order_rows_html;
    document.querySelector("#drug-sale-submission-confirmation .order-sections").innerHTML = order_sections_html;
    this.adjust_layout_for_lifetime();
    M.Modal.getInstance(document.querySelector("#drug-sale-submission-confirmation"), {}).open();
  }

  confirmation_drug_row = (drug_name, strength, quantity, price, refills, pharmacy, instructions) => {
    price = price ? parseFloat(price) : 0
    price = `$${(price/100).toFixed(2)}`
    return `<div class="mt1 table-row"><div class="table-cell"><h4>${drug_name}</h4></div><div class="table-cell"><h4>${strength}mg</h4></div><div class="table-cell"><h4>${quantity}</h4></div><div class="table-cell refills_col"><h4>${refills}</h4></div><div class="table-cell price_col price_container"><h4>${price}</h4></div><div class="table-cell pharmacy_container"><h4>${pharmacy}</h4></div><div class="table-cell instructions_col"><h4>${instructions}</h4></div></div>`
  }

  confirmation_drug_section = (drug_name, strength, quantity, price, refills, pharmacy, instructions) => {
    price = price ? parseFloat(price) : 0
    price = `$${(price/100).toFixed(2)}`
    return `<div class="col s12"><div class="row mb0"><div class="col s12"><h3 class="mb0_25">${drug_name}</h3></div><div class="col s12 mt0_25"><div>Strength (mg): ${strength}</div><div>Quantity: ${quantity}</div><div>Refills: ${refills}</div><div class="price_container">Price: ${price}</div><div class="pharmacy_container">Pharmacy: ${pharmacy}</div><div>Instructions: ${instructions}</div></div></div></div>`
  }

  no_pharmacy_selected = _event => {
    _event.target.setCustomValidity('Please select a pharmacy');
  }

  show_alert = msg => {
    document.getElementById("alert_msg").innerHTML = msg;
    M.Modal.getInstance(document.querySelector("#alert-modal"), {}).open();
  }

  show_target_input = inputTargetName => {
    let el = this.get_input_by_name(inputTargetName)
    if (inputTargetName !== null) {
      el.classList.remove('hide');
    }
  }

  hide_target_input = inputTargetName => {
    let el = this.get_input_by_name(inputTargetName)
    if (el !== null) {
      el.classList.add('hide');
    }
  }
  make_target_input_required = inputTargetName => {
    let el = this.get_input_by_name(inputTargetName)
    if (el !== null) {
      el.required = true;
    }
  }

  make_target_input_unrequired = inputTargetName => {
    let el = this.get_input_by_name(inputTargetName)
    if (el !== null) {
      el.required = false;
    }
  }

  clear_and_make_unrequired = inputTargetName => {
    this.make_target_input_unrequired(inputTargetName)
    let el = this.get_input_by_name(inputTargetName)
    if (el !== null) {
      el.value = "";
    }
  }

  set_select_value = (inputTargetName, value) => {
    let elem;
    if(typeof(inputTargetName) === "string"){
      elem = this[inputTargetName + "Target"];
    } else {
      elem = inputTargetName
    }
    elem.value = value;
    let options = M.FormSelect.getInstance(elem)
    M.FormSelect.init(elem, options)
  }

  slow_show_target_input = inputTargetName => {
    let el = this.get_input_by_name(inputTargetName)
    if (el !== null) {
      el.classList.remove('slow_hide');
      el.classList.add('slow_show');
    }
  }

  slow_hide_target_input = inputTargetName => {
    let el = this.get_input_by_name(inputTargetName)
    if (el !== null) {
      el.classList.remove('slow_show');
      el.classList.add('slow_hide');
      }
  }

  get_input_by_name = (name) => {
    if(this[name + "Target"] !== undefined) {
      return this[name + "Target"];
    } else {
      let el = document.getElementById(name)
      if (el === null) {
        console.log("No element named " + name)
      }
      return el
    }
  }

  is_empty = obj => {
    for (const prop in obj) {
      if (Object.hasOwn(obj, prop)) {
        return false;
      }
    }

    return true;
  }
}
